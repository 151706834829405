.headbar-box{
    display: flex;
    justify-content: space-between;
    padding: 22px 100px;
    align-items: center;
    box-shadow: 0px 4px 50px 0px rgba(185, 185, 185, 0.50);

}
.Links {
    padding: 20px 40px 100px;
}
.select-box-links {
    padding-top: 20px;
}
.left-logo{
    width: 350px;
}
.right-logo{
    width: 60px;
}
.title-section{
    background: #2C3175;
    padding: 30px 0;
    text-align: center;
}
.center-logo{
    width: 200px;
}
.survey-title{
    color: #fff;
    /* h2/Heavy */
    font-family: 'Avenir-Black';
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}
.csx1{
    text-align: justify;
    padding: 40px 80px !important;
}
.survey-short{
    color: #000;

    /* h3/Heavy */
    font-family: 'Avenir-Roman';
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-align: left;
}

.dropdowns {
    display: inline-block;
    position: relative;
  }
  .dropdowns-content {
    display: none;
    position: absolute;
    width: 100%;
    overflow: auto;
    box-shadow: 0px 10px 10px 0px rgba(0,0,0,0.4);
  }
  .dropdowns-content.expand {
    display: block;
    position: relative;
  }
  .dropdowns-content.expand .menus {
    display: flex;
    color: #000000;
    padding: 10px;
    cursor: pointer;
    text-decoration: none;
  }
  .dropdowns-content .menus:hover {
    color: #FFFFFF;
    background-color: #00A4BD;
  }
  .scroll-div{
    overflow-y: auto;
    overflow-x: hidden;
  }
  .questions-card {
    padding: 20px 32px;
    margin: 32px;
}

.qes-type {
    color: #037c09;
}
.qes-type1 {
    color: #873bca;
}
.qes-type,.qes-type1 {
    
    padding-bottom: 10px;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
}
.text-answers{
    
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
}
.sub-text-answer {
    font-size: 16px;
    font-weight: 600;
    padding: 10px;
}
.answer-field {
    color: #037c09;
    padding-bottom: 10px;
    font-size: 14px;
    font-weight: bold;
}

.qes-type .icon-list{
    display: flex;
    align-items: center;
    padding: 6px 8px;
}
.qes-title {
    font-family: 'Avenir-Black';
    font-size: 16px;
}
.choice {
    display: flex;
    align-items: flex-end;
    padding: 5px 20px;
    font-size: 13px;
}
.d-none{
    display: none !important;
}
.addchoice {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    cursor: pointer;
    font-family: 'Avenir-Black';
}
.addchoice svg {
    color: #009688;
    margin-right: 10px;
}
/* .choice label{

} */
.scroll-div {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 500px;
}
.choice textarea,.choice input {
    border-radius: 0;
    border: none;
    border-bottom: 1px solid;
    width: 80%;
    padding: 0px;
}
.qes-delete{
    text-align: right;
}
.qes-feild {
    padding: 10px;
}

label.radio-label {
    margin-left: 10px;
    font-family: 'Avenir-Roman';
}
input.input-feild {
    width: 90%;
    border-radius: 5px;
    background: #F5F5F5;
    box-shadow: inset 0px 1px 2px 0px rgb(0 0 0 / 40%), 0 0 0 0px #19b394;
    border: none;
    height: 61px;
}
.qes-delete button {
    font-size: 14px;
    font-family: 'Avenir-Black';
    text-transform: capitalize;
}
.multiboo {
    font-weight: 600;
    margin-top: 10px;
}

@media screen and (max-width:1220px) {
    .headbar-box{
        padding: 20px 80px;
    }
    .left-logo{
        width: 250px;
    }
    .right-logo{
        width: 50px;
    }
}

@media screen and (max-width:768px) {
    .headbar-box{
        padding: 22px 20px;
    }
    .left-logo{
        width: 150px;
    }
    .right-logo{
        width: 40px;
    }
    .survey-title {
        font-size: 25px;
    }
    .center-logo {
        width: 130px;
    }
    .csx1 {
        padding: 30px 20px !important;
    }
    .user-btn {
        margin-bottom: 40px !important;
    }
    .inner-map-bdy{
        width: auto !important;
    }
    .backimage{
        padding: 80px 40px !important;
        background-size: 0 !important;
        justify-content: center !important;
    }
    .inner-map-bdy .auth-form {
        padding: 60px 40px !important;
        min-width: 350px;
    }

}


.user-qes-label {
    color: #2C3175;
    font-family: 'Avenir-Black';
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    padding-bottom: 10px;
}
.qes-note{
    color: #C42929;  
}
.user-qes-note {
    color: #C42929;
    font-family: 'AVENIR-ROMAN';
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.user-qes-field {
    padding: 20px 0;
    width: 99%;
}
.textarea .user-qes-field {
    padding: 20px 0;
    width: 100%;
}

.radio-field:hover{ 

    border: 1px solid #717FD6;
}

.multiboolen:hover{ 

    border: 1px solid #717FD6;
}
.multiboolen .radio-field{
    border: none;
}
.multiboolen label{
    color: #000;

    /* Body/Medium */
    font-family: 'AVENIR-ROMAN';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.multiboolen  .MuiFormControlLabel-label{
    color: #000;

    /* Body/Medium */
    font-family: 'AVENIR-ROMAN';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.radio-field,.multiboolen {
    justify-content: space-between;
    padding: 10px 20px;
    margin-left: 0px !important;
    margin-bottom: 10px;
    border: 1px solid #BCBCBC;
    background: #FFF;
}
.radio-field .MuiFormControlLabel-label{
    color: #000;

    /* Body/Medium */
    font-family: 'AVENIR-ROMAN';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.radio-field .MuiRadio-root{
    color: #2C3175 !important;
    font-size: 28px !important;
}
select.select-feild {
    color: #000;
    font-family: 'AVENIR-ROMAN';
    font-size: 16px;
    width: 100%;
    padding: 20px;
    border-radius: 0;
    border-color: #bcbcbc;
}
select.select-feild:hover {
    width: 100%;
    padding: 20px;
    border-radius: 0;
    border-color: #2C3175;
}
select.select-feild:focus-visible {
    width: 100%;
    padding: 20px;
    border-radius: 0;
    border-color: #2C3175;
    outline: 0px;
}

.user-qes-short {
    color: #000;
    font-family: 'Avenir-Roman';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.user-question {
    color: #000;
    font-family: 'Avenir-Roman';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-bottom: 5px;
}
.user-btn {
    border-radius: 0 !important;
    padding: 10px 30px !important;
    text-transform: capitalize !important;
    font-family: 'Avenir-Roman' !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 500 !important;
}

.thank-bottom {
    color: #1E1E1E;
    text-align: center;
    font-family: 'Avenir-Black';
    font-size: 23px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}
.thank-box {
    display: flex;
    justify-content: center;
}
.thank-logo {
    width: 350px;
    margin: 30px 0;
}

.user-btn.nxt:hover,.user-btn.pre:hover{
    background-color: #fff !important;
    color: #2c3175 !important;
}
.user-btn.save:hover{
    background-color: #fff !important;
    color: #26975A !important;
}
.user-btn.finish:hover{
    background-color: #fff !important;
    color: #ed6c02 !important;
}

label.sub-label {
    font-size: 16px;
    font-family: 'Avenir-Black';
}
.sub-text-box{
    margin-top: 20px;
}
.progress {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto 50px;
}

.progress div {
    width: 100%;
    font-size: 16px;
    color: #424242;
    font-family: 'Avenir-Roman';
}

.progress div span {
    float: right;
}
.progress .progress1 {
    width: 100%;
    height: 25px;
    margin-top: 10px;
    -webkit-appearance: none;
    appearance: none;
    border: none;
    border-radius: 10px;
}

.progress .progress1::-webkit-progress-value {
    background: #2c3175;
}
.progress .progress1::-webkit-progress-bar {
background-color: #f0f0f0;
}

.progress .progress1::-webkit-progress-bar,
.progress .progress1::-webkit-progress-value {
border-radius: 20px;
}

.progress .progress1::-moz-progress-bar {
border-radius: 20px;
}


.map-bdy{
   
    background: #3a3e75a6;
}
.text-danger.reg-error {
    padding: 10px;
}
.backimage {
    background-repeat: no-repeat;
    padding: 80px;
    background-color: #2C3175;
    background-position: left;
    background-size: 50%;
    display: flex;
    justify-content: flex-end;
}
.form-control {
    border-radius: 5px !important;
}
.sign-head{
    color: #fff;
    font-family: 'Avenir';
    font-size: 47.78px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: -0.956px;
}

.auth-form {
    padding: 60px 100px !important;
    margin-bottom: 80px;
}
.auth-labels {
    color: #fff;
    font-family: 'Avenir-Black';
    font-size: 18px;
}
.forgot-labels {
    color: #fff;
    font-family: 'Avenir-Black';
    font-size: 15px;
    float: right;
}
.btn-sign {
    color: #fff;   
    margin-top: 10px;
    text-align: center;
    cursor: pointer;
    font-family: 'Avenir-Black';
    vertical-align: middle;
    width: 100%;
    -webkit-user-select: none;
    user-select: none;
    padding: 15px;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 5px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border-radius: 5px;
    background-color: #C2612C;
    border-color: #C2612C;
}
.btn-sign:hover{
    background-color: #fff;
    color: #C2612C;
}
.text-sign{
    color: #C2612C;
}
.sign-account {
    color: #fff;
    text-align: center;
    font-size: 18px;
}
.text-sign:hover{
    color: #f5eeeb;
}
.forgot-labels:hover{
    color: #C2612C;
}
.auth-form .form-control {
    margin: 10px 0;
    width: 100%;
}

h3.map-head {
    color: #C5CAE9;
    font-family: 'Avenir-Black';
    text-align: center;
    font-size: 20px;
    margin-top: -10px;
}
.slexpire {
    height: 500px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    color: #303376;
    font-family: Avenir-Black;
}

@import url('https://fonts.googleapis.com/css2?family=Public+Sans');



.sur-title{
 color: rgb(9,44,94);
 font-weight: 700;
 font-size: 26px;
 font-family: 'Jost', sans-serif;
 
}

.sur-card{
    padding:20px 40px;
    background: #3788d814 !important;
}
h5.userMail {
    margin: 0;
    font-size: 20px;
}
.qes-card{
    padding:20px 30px;
    
}
.qes-card.mt-4{
    margin-top: 15px;
}
.MuiFormLabel-colorPrimary{
    font-size: 20px !important;
    color: #000 !important;
    padding-bottom: 20px !important;
    font-weight: 600 !important;
}
.option-qes{
    padding-left: 20px;
}
.question-header {
    /* color: #96689c; */
    color: #092c5e;
    font-size: 23px;
    margin: 2px 0px 20px;
    font-family: 'Jost', sans-serif;
}
.MuiFormControlLabel-label{
    font-size: 18px !important;
    color: #000 !important;
}

.MuiFormControlLabel-labelPlacementEnd.checked {
    background-color: rgba(0,0,0,0.05);
    border: 1px solid #000 !important;
    border-radius: 4px;
}

.MuiFormControlLabel-labelPlacementEnd:hover {
    background-color: rgba(0,0,0,0.05);
    border: 1px dashed #000 !important;
    border-radius: 4px;
}

@media screen and (max-width: 700px) {
    .sur-card{
        padding:20px ;
    }
    .qes-card{
        padding:15px 25px 20px;
        
    }
    .option-qes {
        padding-top: 10px;
        padding-left: 0;
    }
    .MuiFormLabel-colorPrimary{
        font-size: 16px !important;
    }
    .MuiFormControlLabel-label{
        font-size: 16px !important;
        padding-top: 10px;
    }
    .MuiFormControlLabel-root{
        align-items: flex-start !important;
    }
    h5.userMail {
        font-size: 18px;
    }
}

.grp-ct {
    padding: 20px 0 0;
}

.grp-ct label {
    font-family: 'Avenir-Roman';
    font-size: 16px;
}
.grp-ct input {
    border-radius: 5px;
    background: #F5F5F5;
    box-shadow: inset 0px 1px 2px 0px rgb(0 0 0 / 40%), 0 0 0 0px #19b394;
    border: none;
    height: 40px;
    width: 90%;
    margin-top: 10px;
}

.group-ct label {
    font-size: 16px;
    font-family: 'Avenir-Black';
}
.group-ct {
    padding: 10px;
}