/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");


html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
}
@font-face {
  font-family: 'Avenir-Black';
  src: url('./custom/avenir_ff/AvenirLTStd-Black.otf');
}

@font-face {
  font-family: 'Avenir-Roman';
  src: url('./custom/avenir_ff/AvenirLTStd-Roman.otf');
}

.app {
  display: flex;
  position: relative;
}
.logo-bottom {
  border-bottom: 5px solid rgb(242,187,15) !important;
}
.head-logo{
  max-width: 196px;
}
.slexpire {
    height: 400px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    color: #303376;
    font-family: Avenir-Black;
}
.button--submit {
  color: rgb(255,255,255);
  background-color: rgb(0,125,184);
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.my-viewer{
  height: 600px;
  width: 100%;
}
@media screen and (max-width: 700px){
	.head-logo {
    max-width: 150px;
}
}

.swal-title{
  font-size: 24px !important;
  color: #000000 !important;
}
.swal-button {
  background-color: #07afff;
  border-radius: 2px !important;
  font-size: 10px !important;
}
.swal-button--danger {
  background-color: #e64942 !important;
}
.swal-button--cancel {
  color: #000;
  background-color: #efefef !important;
}