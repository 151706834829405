html, body, #root, .app, .content {
    height: auto;
}
.main-area{
    
    background-color: #f3f3f3;
}
.bg-white{
    background-color: #fcfcfc;
}


.ps-menu-root {
    /* height: 100vh; */
    min-height: 100vh;
    height: 100%;
    overflow-y: hidden;
    background: #141b2d;
    color: #fff;
}
.ps-sidebar-root{
    border-color: transparent !important;
}

.ps-menu-root .ps-menu-button:hover{
    color: #302d2d;
}
.ps-menu-label .MuiTypography-root{
    font-size: 16px !important;
}
.MuiButtonBase-root {
    color: #fff;
}
.MuiButtonBase-root:hover,.ps-menu-button:hover .MuiButtonBase-root{
    color: #302d2d;
}

.MuiDataGrid-toolbarContainer{
    padding-top: 10px !important;
    padding-bottom: 5px !important;
}

input::file-selector-button {
    font-weight: bold;
    color: #1976d2;
    padding: 0.6em;
    background-color: #fff;
    border: thin solid #1976d2;
    border-radius: 3px;
  }
.bottom-control{
    text-align: right;
    padding-top: 2rem;
}
.md-title{
    font-size: 25px;
    color: #1A237E;
    padding-left: 20px;
}
.clabel{
    display: block;
    padding: 10px;
    font-size: 16px;
    font-weight: 700;
    width: 100%;
    margin-left: 10px;
}
.form-control{
    padding: 16px;
    margin-left: 20px;
    width: 90%;
    border: 1px solid #003b95;
    border-radius: 5px;
}
span.req-label {
    color: red;
}
.overflox-scroll-form{
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 650px;
    padding: 0 10px 10px;
}
.MuiDataGrid-root {
    font-size: 14px !important;
}
.modal-close{
    background: transparent !important;
}
.modal-close:hover{
    color: #1976d2;
}


.cards {
    margin-bottom: 1.875rem;
    background-color: #fff;
    transition: all .5s ease-in-out;
    position: relative;
    border: 0px solid transparent;
    border-radius: 5px;
    box-shadow: 0px 6px 24px 0px rgba(53, 55, 81, 0.06);
    height: calc(100% - 30px);
    cursor: pointer;
}
.widget-stat.cards:hover{
    box-shadow: 1px 6px 20px 9px #0000004a; 
}
.card-body {
    flex: 1 1 auto;
    padding: 1.875rem;
}
.widget-stat .media {
    padding: 0px 0;
    align-items: center;
}

.media {
    display: flex;
    align-items: flex-start;
}

.widget-stat .media > span {
    background: #F5F7FB;
    height: 80px;
    width: 80px;
    border-radius: 50px;
    padding: 10px 12px;
    border: 1px solid #E6E8ED;
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #464a53;
}

.me-3 {
    margin-right: 1rem !important;
}
.media .media-body {
    flex-grow: 1;
    /* padding-left: 20px; */
    text-align: center;
}
.media-body {
    flex: 1;
}
.text-white {
    color: #fff !important;
}
.widget-stat .media .media-body p {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 1rem;
    margin-top: 0;
}
img.admin-logo {
    width: 100%;
    margin: auto;
    overflow: hidden;
    border-radius: 20px;
}
.mb-1 {
    margin-bottom: 0.25rem !important;
}
.bg-primary {
    /* background-color: #6a73fa !important; */
    background: linear-gradient(87deg,#5e72e4 0,#825ee4 100%)!important;
}
.bg-warning {
    /* background-color: rgb(255, 170, 22) !important; */
    background: linear-gradient(87deg,#f5365c 0,#f56036 100%)!important;
}
span.badge-blue {
    background: #AB47BC;
    color: #fff;
    padding: 4px 10px;
    margin-left: 20px;
    border-radius: 15px;
}
span.badge-green {
    background: #06a796;
    color: #fff;
    padding: 4px 10px;
    margin-left: 20px;
    border-radius: 15px;
}
span.badge-red {
    background: #f5365c;
    color: #fff;
    padding: 4px 10px;
    margin-left: 20px;
    border-radius: 15px;
}

.bg-secondary {
    background-color: #1E88E5;
}
.bg-info {
    /* background-color: rgb(103, 59, 183) !important; */
    background: linear-gradient(87deg,#06a796 0,#01b401 100%)!important;
}
.bg-sent {
    /* background-color: rgb(103, 59, 183) !important; */
    background: linear-gradient(116deg,#f6e05e 0,#f0a308 100%)!important;
}
.bg-danger {
    background-color: rgb(255, 22, 22) !important;
    color: #fff !important;
}
.bg-success {
    background-color: #7ED321 !important;
    color: #fff !important;
}
.badge {
    font-weight: 600;
    padding: 4px 15px;
    font-size: 13px;
    border-radius: 15px;
}
.h3{
    font-size: 1.8rem !important;
    margin: 0;
}
.widget-stat .media > span > svg {
    font-size: 38px;
    color: #464a53;
}
.card-header {
    border-bottom:1px solid #e6e6e6;
    position: relative;
    background: transparent;
    padding: 1rem 1.5rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.card-header .card-title {
    margin: 0px;
}

.card-title {
    font-size: 1.25rem !important;
    font-weight: 500;
    color: #312a2a;
    text-transform: capitalize;
}
.pt-2 {
    padding-top: 0.5rem !important;
}
.table-responsive {
    overflow-x: auto;
    scrollbar-width: thin;
}
.recentOrderTable table,table.sum-table {
    margin-bottom: 0;
    overflow: hidden;
}
.table tbody tr td {
    vertical-align: middle;
    border-bottom:1px solid #e6e6e6;
}

.table-responsive-md {
    min-width: 29rem !important;
}

.text-right{
    text-align: right !important;
}
.text-center {
    text-align: center !important;
}
.text-left {
    text-align: left !important;
}

.recentOrderTable table thead th,.sum-table thead th {
    font-size: 1rem;
}
.table.sum-table tbody tr td {
    vertical-align: middle;
    border-bottom: 1px solid #E0E0E0;
}
.table thead th,.table tfoot th {
    border-bottom: 1px solid #e6e6e6;
    text-transform: capitalize;
    color: #312a2a;
    font-size: 1rem;
    white-space: nowrap;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-align: left;
}
.table th, .table td {
    border-color: #080707;
    color: #212120;
    padding: 0.7375rem 0.8375rem;
}
.recentOrderTable tr {
    border: 1px solid #000 !important;
    border-color: #212121;
}
.sum-table tr {
    border: 1px solid #000 !important;
    border-color: #212121;
}

.sum-card {
    padding: 20px 40px;
    background: #f3f3f3 !important;
    box-shadow: none !important;
}

.sum-table thead,.sum-table tfoot{
    background-color: #E0E0E0;
}
table.sum-table {
    border: 1px solid #E0E0E0;
    margin-left: auto;
    margin-right: auto;
}


  
  .dropdn {
    position: relative;
    display: inline-block;
  }
  
  .dropdn-content {
    display: none;
    position: absolute;
    background-color: #ffffff;
    min-width: 150px;
    margin-left: -100px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}
  
  .dropdn-content div {
    color: #141b2d;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    font-family: 'Avenir-Roman';
    text-align: center;
}
.dropdn-content div.logout {
    cursor: pointer;
    border-top: 1px solid #BDBDBD;
    display: flex;
    justify-content: space-evenly;
}
.dropdn-content div.logout:hover {
    color: coral;
    background-color: #ddd;
}
  
  
  .dropdn:hover .dropdn-content {display: block;}
  
  .dropdn:hover .dropbtn {
    background-color: #141b2d;
    color: #fff;
}
.dropdn .dropbtn{
    background-color: #E0E0E0;
    /* color: #fff; */

}
.sel-sur-dash {
    background: #fff;
    width: 50%;
}

.expo-pr{
    float: right;
    display: none;
}
.charts-pos,.charts-pos1{
    max-width: 800px !important;
    margin: auto;
}
.selectsurvey {
    padding-left: 10px;
    font-weight: 600;
    font-size: 18px;
}
.dash-page-title {
    font-size: 22px !important;
    font-family: 'Avenir-Black' !important;
    display: flex;
    align-items: center;
}
.dash-page-title svg {
    margin-right: 10px;
}
.dash-page-subhead {
    color: #807f9c;
    font-size: 14px !important;
    font-family: 'Avenir-Roman' !important;
    margin-left: 30px !important;
}
.header-box {
    margin: -20px -20px 40px -20px !important;
    padding: 30px 20px 30px 40px !important;
    background-color: #fff!important;
    box-shadow: 0 4px 50px 0 #b9b9b9!important;
}


@media print {
    .expo-pr{
        display: none
    }
    .questions-card{
       padding: 20px !important;
       margin: 0px !important;
    }
    .charts-pos{
        max-width: 540px !important;
        margin-left: -40px  !important;
    }
    .charts-pos1{
        max-width: 540px !important;
        margin-left: -32px  !important;
    }
    table.sum-table {
        width: auto !important;
    }
    .sum-table thead th {
        font-size: 1rem;
    }
    .sum-card{
        padding: 20px !important;
        
        page-break-after: always;
    }
    h3{
        
        page-break-before: always;
    }
    /* .charts-pos svg,.charts-pos svg rect{
        width: 692px !important;
        height: 388px !important;
    }
    .charts-pos svg{
        left: 0 !important;
    } */
}