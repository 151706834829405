.upload-btn {
    color: #fff !important;
    background: blueviolet !important;
  }
  
  .upload-btn:hover{
    
    background-color: #fff;
    color: blueviolet;
  }

  .ps-menu-button.ps-active {
    background-color: #f3f3f3 !important;
}

.ps-menu-root .ps-menu-button.ps-active {
    color: #302d2d !important;
}
  
.sample-dwn{
  color: #084cdf !important;
  text-decoration: underline !important;
}
  .up-btn{
    color: #fff;
    background: #084cdf;
    border: 0px;
    border-radius: 5px;
    padding: 5px 20px;
    cursor: pointer;
}
  /* input[type=file]::file-selector-button {
    margin-right: 20px;
    border: none;
    background: #084cdf;
    padding: 10px 20px;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    transition: background .2s ease-in-out;
  }
  input[type=file]{
    color: #000;
    border-color: #000;
    outline-color: #000;
  }
  
  input[type=file]::file-selector-button:hover {
    background: #0d45a5;
  } */

  span.ic-val {
    /* color: lightseagreen; */
    font-size: 40px;
    font-weight: 700;
}

.ic-icon{
    /* color: #1da570; */
    font-size: 90px !important;
}
.icon-1{
  color: rgb(25, 118, 210);
}
.icon-2{
  color:#1da570;
}
.text-center{
  text-align: center;
}

.MuiDataGrid-columnHeaders,.super-app-theme--header, .MuiDataGrid-columnHeaderCheckbox {
  background: #1565C0;
  color: #fff;
}

.MuiDataGrid-columnHeaderCheckbox  .MuiCheckbox-root.Mui-checked{
  color: #fff !important;
}
.MuiDataGrid-columnHeaderCheckbox  .MuiCheckbox-root{
  color: #fff !important;
}